<template>
  <div>
    <div class="content_box beforetab">
      <table class="tbl_search">
        <colgroup>
          <col width="70px"><col>
          <col width="100px"><col>
          <col width="70px"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONEX030T010.009') }}<!-- B/L No. --></th>
            <td>{{ freightBlNo }}</td>
            <th>{{ $t('msg.ONEX030T010.010') }}<!-- Booking No. --></th>
            <td>{{ bkgNo }}</td>
            <th>{{ $t('msg.ONEX030T010.011') }}<!-- S/C No. --></th>
            <td>{{ blInfo.frtAppNo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="memberDetail.userCtrCd !== 'IN'" class="content_box mt10">
      <table class="tbl_search">
        <colgroup>
          <col width="70px"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONEX030T010.012') }}<!-- Invoice --></th>
            <td>
              <div v-for="(item) in invoiceList" :key="item" class="link-pdf">
                <a href="#" @click.prevent="openInvoice">{{ item }}</a> <button class="tbl_icon pdf" @click.prevent="openInvoice">첨부</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content_box mt10">
      <table v-if="isDisabled()" class="tbl_search">
        <colgroup>
          <col width="200px"><col>
        </colgroup>
        <tbody>
          <tr>
            <td>
              <h2 class="content_title"></h2>
            </td>
            <td class="text_right">
              <span class="box_info past">
                <span v-if="chargeInfo.blYn === 'Y'" class="note">{{ $t('msg.ONEX030T010.026') }}<!-- 발행된 B/L입니다. --></span>
                <span v-else-if="chargeInfo.eobYn === 'Y'" class="note">{{ $t('msg.ONEX030T010.027') }}<!-- 마감된 B/L입니다. --></span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h2 v-if="this.ofPCount > 0" class="content_title">{{ $t('msg.ONEX030T010.013') }}<!-- Ocean Freigh --></h2>
      <table v-if="this.ofPCount > 0" class="tbl_col">
        <colgroup>
          <col style="width:40%">
          <col style="width:10%">
          <col style="width:20%">
          <col style="width:15%">
          <col style="width:15%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.ONEX030T010.016') }}<!-- Description --></th>
            <th>{{ $t('msg.ONEX030T010.017') }}<!-- CUR --></th>
            <th>{{ $t('msg.ONEX030T010.018') }}<!-- Amount --></th>
            <th colspan="2">{{ $t('msg.ONEX030T010.019') }}<!-- Payment Term --></th>
          </tr>
        </thead>
        <tbody v-if="chargeData.oceanFreight.length >0">
          <template v-for="item in chargeData.oceanFreight">
            <tr v-if="item.frtPncCd == 'P'" :key="item.caSeq">
              <!-- Prepaid만 보여준다 -->
              <td class="text-left">{{ item.frtNm }}</td>
              <td>{{ item.occrCurCd }}</td>
              <td class="text_right">{{ getCurrencyFormat(item.occrCurCd, item.occrAmt) }}</td>
              <td colspan="2">
                <template v-if="item.frtPncCd == 'P'">
                  {{ $t('msg.ONEX030T010.020') }}<!-- Prepaid -->
                </template>
                <template v-else-if="item.frtPncCd == 'C'">
                  {{ $t('msg.ONEX030T010.021') }}<!-- Collect -->
                </template>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">No data.</td>
          </tr>
        </tbody>
      </table>

      <h2 class="content_title">{{ $t('msg.ONEX030T010.014') }}<!-- Local Charge (POL) --></h2>
      <table class="tbl_col">
        <colgroup>
          <col style="width:40%">
          <col style="width:10%">
          <col style="width:20%">
          <col style="width:15%">
          <col style="width:15%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.ONEX030T010.016') }}<!-- Description --></th>
            <th>{{ $t('msg.ONEX030T010.017') }}<!-- CUR --></th>
            <th>{{ $t('msg.ONEX030T010.018') }}<!-- Amount --></th>
            <th colspan="2">{{ $t('msg.ONEX030T010.019') }}<!-- Payment Term --></th>
          </tr>
        </thead>
        <tbody v-if="chargeData.polCharge.length >0">
          <tr v-for="item in chargeData.polCharge" :key="item.caSeq">
            <td class="text-left">{{ item.frtNm }}</td>
            <td>{{ item.occrCurCd }}</td>
            <td class="text_right">{{ getCurrencyFormat(item.occrCurCd, item.occrAmt) }}</td>
            <td>
              <input type="radio" :id="`pay-term-${item.caSeq}-1`" :name="`pay-term-${item.caSeq}`" :value="'P'" v-model="item.frtPncCd" :disabled="isDisabled()" @click="onPcClick($event)">
              <label :for="`pay-term-${item.caSeq}-1`"><span></span>{{ $t('msg.ONEX030T010.020') }}<!-- Prepaid --></label>
            </td>
            <td>
              <input type="radio" :id="`pay-term-${item.caSeq}-2`" :name="`pay-term-${item.caSeq}`" :value="'C'" v-model="item.frtPncCd" :disabled="isDisabled()" @click="onPcClick($event)">
              <label :for="`pay-term-${item.caSeq}-2`"><span></span>{{ $t('msg.ONEX030T010.021') }}<!-- Collect --></label>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">No data.</td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-for="(item, i) in chargeTotal.pol" :key="item.occrCurCd">
            <th v-if="i == 0" :rowspan="chargeTotal.pol.length">Total</th>
            <th style="border-left: 1px solid #e1e3eb;">{{ item.occrCurCd }}</th>
            <th class="text_right">{{ getCurrencyFormat(item.occrCurCd, item.total) }}</th>
            <th colspan="2"></th>
          </tr>
        </tfoot>
      </table>
      <p v-show="chargeData.polCharge.length > 0" class="text_right mt10">
        <a v-if="!isInvoicePub" class="button sm blue" href="#" @click.prevent="invoicePublish">{{ $t('msg.ONEX030T010.007') }}<!-- Invoice 발행 --></a>
        <a v-show="auth.userCtrCd === 'KR'" class="button sm blue ml5" href="#" @click.prevent="requestFreightModify">{{ $t('msg.ONEX030T010.008') }}<!-- 운임 수정 요청 --></a>
      </p>

      <h2 class="content_title">{{ $t('msg.ONEX030T010.015') }}<!-- Local Charge (POD) --></h2>
      <table class="tbl_col">
        <colgroup>
          <col style="width:40%">
          <col style="width:10%">
          <col style="width:20%">
          <col style="width:15%">
          <col style="width:15%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.ONEX030T010.016') }}<!-- Description --></th>
            <th>{{ $t('msg.ONEX030T010.017') }}<!-- CUR --></th>
            <th>{{ $t('msg.ONEX030T010.018') }}<!-- Amount --></th>
            <th colspan="2">{{ $t('msg.ONEX030T010.019') }}<!-- Payment Term --></th>
          </tr>
        </thead>
        <tbody v-if="chargeData.podCharge.length >0">
          <tr v-for="item in chargeData.podCharge" :key="item.caSeq">
            <td class="text-left">{{ item.frtNm }}</td>
            <td>{{ item.occrCurCd }}</td>
            <td class="text_right">{{ getCurrencyFormat(item.occrCurCd, item.occrAmt) }}</td>
            <td>
              <input type="radio" :id="`pay-term-${item.caSeq}-1`" :name="`pay-term-${item.caSeq}`" :value="'P'" v-model="item.frtPncCd" :disabled="isDisabled()" @click="onPcClick($event)">
              <label :for="`pay-term-${item.caSeq}-1`"><span></span>{{ $t('msg.ONEX030T010.020') }}<!-- Prepaid --></label>
            </td>
            <td>
              <input type="radio" :id="`pay-term-${item.caSeq}-2`" :name="`pay-term-${item.caSeq}`" :value="'C'" v-model="item.frtPncCd" :disabled="isDisabled()" @click="onPcClick($event)">
              <label :for="`pay-term-${item.caSeq}-2`"><span></span>{{ $t('msg.ONEX030T010.021') }}<!-- Collect --></label>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">No data.</td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-for="(item, inx) in chargeTotal.pod" :key="item.occrCurCd">
            <th v-if="inx === 0" :rowspan="chargeTotal.pod.length">{{ $t('msg.ONEX030T010.022') }}<!-- Total --></th>
            <th>{{ item.occrCurCd }}</th>
            <th class="text_right">{{ getCurrencyFormat(item.occrCurCd, item.total) }}</th>
            <th colspan="2"></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="flex_box mt10">
      <a v-show="auth.userCtrCd === 'KR'" class="button lg" href="#" @click.prevent="goWorkingList">{{ $t('msg.ONEX030T010.025') }}<!-- 목록 --></a>
      <button v-show="auth.userCtrCd === 'KR'" type="button" class="button blue lg ml_auto" @click="save">{{ $t('msg.CSBK100.178') }}<!-- 저장 --></button>
    </div>
    <div id="layerInvoice">
      <win-layer-pop>
        <component
          v-if="custCompInvoice"
          :is="custCompInvoice"
          :is-show-pop="isInvoiceShowPop"
          :parent-info="invoiceParam"
          :detail="requestDetail"
          @close="closeInvoce"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import API from '@/api/rest/trans/freight'
import CalcIssue from '@/api/rest/settle/calcIssue'
import BlInvoiceFreightPop from '@/pages/pdf/popup/BlInvoiceFreightPop'
import { rootComputed } from '@/store/helpers'
import reportInfo from '@/api/rest/trans/reportInfo'

export default {
  name: 'FreightBL',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    FreightPop: () => import('@/pages/settle/popup/CalcIssueFreightPop'),
    BlInvoiceFreightPop
  },
  props: {
    parentData: {
      type: Object,
      default: function () {
        return {
          isWorking: false,
          eiCatCd: ''
        }
      }
    },
    detailBkgNo: {
      type: String,
      default: ''
    },
    detailBlNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      canLeaveSite: false,
      custCompInvoice: null,
      isInvoiceShowPop: false,
      isInvoicePub: true,
      invoiceParam: {},
      requestDetail: {},
      freightBlNo: '',
      freightEiCatCd: '',
      bkgNo: '',
      blInfo: {
        blNo: '',
        bkgNo: '',
        podCtrCd: '',
        polCtrCd: '',
        eiCatCd: 'O',
        frtAppNo: ''
      },
      blInfoList: [],
      invoiceList: [],
      invoiceDetailList: [],
      chargeInfo: {},
      chargeList: [],
      sourceChargeList: [],
      chargeData: {
        oceanFreight: [],
        polCharge: [],
        podCharge: []
      },
      chargeTotal: {
        pol: [],
        pod: []
      },
      ldCatCd: 'L', // 선적 양하 구분 코드('L', 'D')
      chkFixChargeList: [],
      changeList: [],
      ofPCount: 0 // Ocean Freight의 Prepaid Count
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    detailBlNo (n, o) {
      // console.log('Freight Tab > B/L. No >', n)
      if (n !== o) {
        this.freightBlNo = n
        this.init()
      }
    }
  },
  created () {
  },
  mounted () {
    console.log('Freight Tab > B/L. No > mounted >', this.detailBlNo)
    console.log('Freight Tab > B/L. No > mounted > parentData.eiCatCd', this.parentData.eiCatCd)

    this.bkgNo = this.detailBkgNo
    this.freightBlNo = this.detailBlNo
    this.freightEiCatCd = this.parentData.eiCatCd
    // this.bkgNo = 'KR02886743'
    // this.freightBlNo = 'PUS705964'

    // 운임조회 권한 체크
    this.setItems()
  },
  methods: {
    async setItems () {
      let eiCatCd = this.freightEiCatCd || ''

      const strParams = [] //추가정보 조회 파라미터
      let authYn = []
      strParams.push('eiCatCd=' + eiCatCd)
      strParams.push('blNo=' + this.freightBlNo)

      await reportInfo.getFreightBlAuthYn(strParams.join('&'))
        .then(async res => {
            authYn = res.data
        })

      if (authYn === 'Y') {
        this.init()
      } else {
        this.openAlert(this.$t('msg.ONIM070G010.038')) //'권한이 없습니다.'
      }
    },
    async init () {
      // console.log('Component Start!')
      if (this.parentData.isWorking) {
        await this.searchBlInfo()
        await this.searchInvoiceInfo()
        await this.searchInvoiceDetailInfo()
        await this.searchChargeList()
      }
    },
    async refresh () {
      await this.searchBlInfo()
      await this.searchInvoiceInfo()
      await this.searchInvoiceDetailInfo()
      await this.searchChargeList()
    },
    async searchBlInfo () {
      await API.searchBlInfo(this.freightBlNo)
      .then(response => {
        // console.log('searchBlInfo >>', response)
        if (response.data) {
          if (response.data.blInfoList) {
            this.blInfoList = response.data.blInfoList
            if (this.blInfoList.length > 0) {
              const tmpBlInfo = this.blInfoList.filter(v => v.bkgNo === this.bkgNo)
              if (tmpBlInfo.length > 0) {
                this.blInfo = tmpBlInfo[0]
              } else {
                this.blInfo = this.blInfoList[0]
              }
              if (this.blInfo.eiCatCd === 'O') {
                // 수출
                this.ldCatCd = 'L'
              } else {
                // 수입
                this.ldCatCd = 'D'
              }
            }
          }
        }
      })
    },
    async searchInvoiceInfo () {
      await API.searchInvoiceInfo(this.freightBlNo)
      .then(response => {
        // console.log('searchInvoiceInfo >>', response)
        if (response.data) {
          this.invoiceList = response.data.invoiceNo
        }
      })
    },
    async searchInvoiceDetailInfo () {
      await API.searchInvoiceDetailInfo(this.freightBlNo)
      .then(response => {
        // console.log('searchInvoiceDetailInfo >>', response)
        if (response.data) {
          this.invoiceDetailList = response.data

          // Invoice 발행대상 유무 체크
          const invRnoList = this.invoiceDetailList.filter(v => v.ldCatCd === this.ldCatCd && v.invIssNo === '' && v.invRno !== '')
          if (invRnoList.length > 0) {
            this.isInvoicePub = false
          } else {
            this.isInvoicePub = true
          }
        }
      })
    },
    async searchChargeList () {
      await API.searchChargeList(this.freightBlNo)
      .then(response => {
        if (response.data) {
          this.chargeInfo = response.data
          this.chargeList = this.$_.cloneDeep(response.data.chargeList)
          this.sourceChargeList = this.$_.cloneDeep(response.data.chargeList)
          let occrCurCdList = []
          this.chargeList.forEach(v => {
            const inx = occrCurCdList.findIndex(cd => { return v.occrCurCd === cd })
            if (inx === -1) {
              occrCurCdList.push(v.occrCurCd)
            }
          })

          this.chargeData.oceanFreight = this.chargeList.filter(v => { return v.frtCd === 'O/F' })
          this.ofPCount = this.chargeData.oceanFreight.filter(v => v.frtPncCd === 'P').length // Ocean Freight의 Prepaid 갯수
          this.chargeData.polCharge = this.chargeList.filter(v => { return v.ldCatCd === 'L' })
          this.chargeData.podCharge = this.chargeList.filter(v => { return v.ldCatCd === 'D' })

          this.chargeTotal.pol = []
          occrCurCdList.forEach(cd => {
            let tot = this.chargeData.polCharge.reduce((tot, v) => {
              if (v.occrCurCd === cd) {
                return tot + v.occrAmt
              }
              return tot + 0
            }, 0)
            if (tot !== 0) {
              this.chargeTotal.pol.push({
                occrCurCd: cd,
                total: tot
              })
            }
          })

          this.chargeTotal.pod = []
          occrCurCdList.forEach(cd => {
            let tot = this.chargeData.podCharge.reduce((tot, v) => {
              if (v.occrCurCd === cd) {
                return tot + v.occrAmt
              }
              return tot + 0
            }, 0)
            if (tot !== 0) {
              this.chargeTotal.pod.push({
                occrCurCd: cd,
                total: tot
              })
            }
          })

          this.chkFixChargeList = this.chargeList.filter(v => v.fixYn !== undefined && v.fixYn === 'Y')
        } else {
          this.chkFixChargeList = []
        }
      })
    },
    async requestFreightModify () {
      if (this.chargeInfo.blYn === 'Y') {
        this.openAlert(this.$t('msg.ONEX030T010.023')) // B/L 발행 이후는 운임 수정 요청이 불가능 합니다.
        return
      } else if (this.chargeInfo.eobYn === 'Y') {
        this.openAlert(this.$t('msg.ONEX030T010.024')) // EOB 이후는 운임 수정 요청이 불가능 합니다.
        return
      }

      this.openFreightModify()
      // const param = {
      //   reqCatCd: '12',
      //   docRno: this.freightBlNo
      // }
      // // console.log(JSON.stringify(param))
      // await CalcIssue.reqChk(param)
      //   .then(response => {
      //     // console.log('Bl Check >', response)
      //     if (response.data) {
      //       const re = response.data
      //       if (re.rstCd === 'Y') {
      //         this.openFreightModify()
      //       } else {
      //         if (!this.$_.isEmpty(re.rstMsg)) {
      //           this.$ekmtcCommon.alertDefault(re.rstMsg)
      //         }
      //       }
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },

    isModify () {
      this.saveDataCollection()

      let isModify = false
      this.sourceChargeList.forEach((sv) => {
        const vos = this.chargeList.filter(v => { return sv.caNo === v.caNo && sv.caCd === v.caCd && sv.caSeq === v.caSeq })
        // console.log(vos)
        if (vos.length > 0) {
          const vo = vos[0]
          if (sv.frtPncCd !== vo.frtPncCd) {
            isModify = true
          }
        }
      })
      // console.log('isModify >', isModify)
      return isModify
    },

    openFreightModify () {
      this.custCompInvoice = 'FreightPop'
      this.requestDetail = {
        remarkCd: '',
        blNo: this.freightBlNo
      }
      this.isInvoiceShowPop = true
      $('#layerInvoice > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    isDisabled () {
      if (this.auth.userCtrCd !== 'KR') return true

      if (this.chargeInfo === undefined || this.chargeInfo.blNo === undefined) {
        return true
      } else if (this.chargeInfo.eobYn === 'Y' || this.chargeInfo.blYn === 'Y') {
        return true
      }
      return false
    },
    onPcClick (e) {
      if (!this.canSave()) {
        if (this.chargeInfo.invIssYn === 'Y') {
          e.preventDefault()
          this.openAlert(this.$t('msg.ONEX030T010.003')) // 인보이스가 생성되어 있으므로 수정 불가합니다. 인보이스 취소 요청 후 재수정 바랍니다.
        }
      }
    },
    canSave () {
      // 1. EOB 이후 불가
      // 2. B/L 발행 이후 불가
      // 3. 인보이스 발행 이후 불가
      if (this.chargeInfo === undefined || this.chargeInfo.blNo === undefined) {
        return false
      } else if (this.chargeInfo.eobYn === 'Y' || this.chargeInfo.blYn === 'Y' || this.chargeInfo.invIssYn === 'Y') {
        return false
      }
      return true
    },
    save () {
      if (!this.canSave()) {
        if (this.chargeInfo.blYn === 'Y') {
          this.openAlert(this.$t('msg.ONEX030T010.001')) // B/L 발행 이후는 수정이 불가능 합니다.
        } else if (this.chargeInfo.eobYn === 'Y') {
          this.openAlert(this.$t('msg.ONEX030T010.002')) // EOB 이후는 수정이 불가능 합니다.
        } else if (this.chargeInfo.invIssYn === 'Y') {
          this.openAlert(this.$t('msg.ONEX030T010.003')) // 인보이스가 생성되어 있으므로 수정 불가합니다. 인보이스 취소 요청 후 재수정 바랍니다.
        }
        return
      }
      this.saveDataCollection()
      this.saveData()
    },

    saveDataCollection () {
      let param = []
      //this.chkFixChargeList = this.chargeList

      this.chargeData.oceanFreight.forEach(v => {
        this.sourceChargeList.some((sv) => {
          // console.log(vos)
          if (v.caSeq === sv.caSeq && v.frtPncCd !== sv.frtPncCd) {
            param.push(v)
            return true
          }
        })
      })
      this.chargeData.polCharge.forEach(v => {
        this.sourceChargeList.some((sv) => {
          if (v.caSeq === sv.caSeq && v.frtPncCd !== sv.frtPncCd) {
            this.changeFrtPncCd(v)
            param.push(v)
            return true
          }
        })
      })
      this.chargeData.podCharge.forEach(v => {
        this.sourceChargeList.some((sv) => {
          if (v.caSeq === sv.caSeq && v.frtPncCd !== sv.frtPncCd) {
            this.changeFrtPncCd(v)
            param.push(v)
            return true
          }
        })
      })
      this.changeList = param
    },

    async saveData () {
      // const param = this.changeList
      let chkFix = false
      let chkFixArr = []

      if (this.changeList.length > 0) {
        this.chkFixChargeList.forEach(v => {
          // if (v.fixYn !== undefined && v.fixYn === 'Y') {
          //   chkFix = true
          //   chkFixArr.push(v.frtNm)
          // }
          this.changeList.forEach(sv => {
            if (v.caSeq === sv.caSeq) {
              chkFix = true
            }
          })
        })

        // console.log(param)
        if (chkFix) {
          //this.openAlert(this.$t('msg.ONEX030T010.004', { str: this.chkFixChargeList.join(',') })) // {str} 항목은 변경 불가하오니 담당자 컨택바랍니다
          this.openAlert(this.$t('msg.ONEX030T010.004', { str: this.chkFixChargeList.map(v => v.frtNm).join(',') })) // {str} 항목은 변경 불가하오니 담당자 컨택바랍니다
        } else {
          await API.updateData(this.changeList)
          .then(async response => {
            // console.log(response)
            if (response.data) {
              await this.refresh()
              this.openAlert(this.$t('tem.CMAT013')) // 저장되었습니다.
            }
          })
        }
      }
    },

    openInvoice () {
      this.invoiceParam = {
        testCtrCd: '',
        testAgtCd: '',
        testBkgPlcCd: '',
        items: []
      }

      let tmpData = []

      this.invoiceList.forEach(invIssNo => {
        const invoceInfo = {
          invIssNo: invIssNo,
          to: '',
          remark: '',
          filter: 'BL',
          blNo: this.freightBlNo,
          esign: ''
        }
        tmpData.push(invoceInfo)
      })

      const cstCd = this.auth.cstCd
      let componentName
      if (cstCd === 'ID') {
        // 인도네시아
        componentName = 'BlProformaInvoiceIdPop'
        if (tmpData.length === 0) {
          this.openAlert(this.$t('msg.ONEX030T010.006')) // Invoice를 출력할 B/L이 없습니다.
          return
        }
        this.invoiceParam.items = tmpData
      } else {
        if (tmpData.length === 0) {
          this.openAlert(this.$t('msg.ONEX030T010.006')) // Invoice를 출력할 B/L이 없습니다.
          return
        }
        componentName = 'BlInvoiceFreightPop'
        console.log(tmpData)
        this.invoiceParam.items = tmpData
        console.log('PDF >>', this.invoiceParam)
      }
      this.custCompInvoice = componentName
      this.isInvoiceShowPop = true
      $('#layerInvoice > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },

    closeInvoce () {
      this.isInvoiceShowPop = false
      $('#layerInvoice > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    },

    displayInvoiceDetail () {
    },
    invoicePublish () {
      const blInvoiceParam = {
        asLangCd: this.auth.serviceLang,
        inBound: this.blInfo.eiCatCd,
        blList: []
      }
      const blList = []

      if (this.invoiceDetailList.length > 0) {
        const localChargeList = this.invoiceDetailList.filter(v => v.ldCatCd === this.ldCatCd && v.invIssNo === '')
        let invRnoList = []
        localChargeList.forEach(v => {
          if (v.invRno !== '') {
            if (this.$_.indexOf(invRnoList, v.invRno) === -1) {
              invRnoList.push(v.invRno)
            }
          }
        })

        invRnoList.forEach(rno => {
          const tmp = {
            blNo: this.freightBlNo,
            invRno: invRnoList[0],
            index: 0,
            mainYn: 'N'
          }
          blList.push(tmp)
        })
      }

      if (blList.length > 0) {
        blInvoiceParam.blList = blList

        let rtn
        try {
          CalcIssue.invoicePublish(data)
            .then(async response => {
              // console.log('Invoice 번호 생성 결과 >>')
              // console.log(response)
              if (response.data) {
                await this.refresh()
                this.openInvoice()
              }
            })
        } catch (e) {
          console.log(e)
        } finally {
          console.log('!종료')
        }
      }
    },

    async goWorkingList (e) {
      // console.log(e)
      if (this.canSave() && this.isModify()) {
        // 변경사항 있음
        this.confirmCallBack(this.$t('msg.ONEX030T010.005'), // 수정된 내용이 있습니다. 저장하시겠습니까?
          async () => {
            await this.saveData()
          }, () => {
            this.moveRoute()
          })
      } else {
        this.moveRoute()
      }
    },

    moveRoute () {
      this.$router.push({ path: '/working/progress' })
    },

    confirmCallBack (msg, onConfirm, onClose) {
      let obj = {
        alertType: 'simple',
        customCloseBtnText: '취소',
        customConfirmBtnText: '확인',
        isEnter: true,
        useConfirmBtn: true,
        message: msg,
        onClose: onClose,
        onConfirm: onConfirm
      }
      this.$ekmtcCommon.alert(obj)
    },

    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    getCurrencyFormat (cstCd, number) {
      if (cstCd === 'KRW') {
        return new Intl.NumberFormat('ko-KR', { currency: 'KRW' }).format(number)
      } else if (cstCd === 'VND') {
        return new Intl.NumberFormat('ko-KR', { currency: 'VND' }).format(number)
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          currency: 'USD'
        }).format(number)
      }
    },
    changeFrtPncCd (item) {
      const blInfo = this.blInfo

      const flag = item.frtPncCd === 'P' ? 'pol' : 'pod'
      const ctrCd = blInfo[flag + 'CtrCd'] || ''
      const portCd = blInfo[flag + 'PortCd'] || ''

      item.payCtrCd = ctrCd
      item.payPlcCd = portCd
    }
  }
}
</script>

<style scoped>
.link-pdf {
  display: inline-block;
  line-height: 1.8rem;
  width: 150px;
  border: 0px solid red;
  margin-right: 0.8rem;
}

.link-pdf a {
  vertical-align: middle;
  padding-right: 8px;
}
</style>
