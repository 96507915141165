import Send from '../../trans.client.js'

const baseUrl = '/trans/freight'

export default {
  searchBlInfo (blNo) {
    return new Promise((resolve, reject) => {
      Send({
        url: `${baseUrl}/bl-info`,
        method: 'GET',
        data: {},
        params: {
          blNo: blNo
        }
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  searchInvoiceInfo (blNo) {
    return new Promise((resolve, reject) => {
      Send({
        url: `${baseUrl}/invoice`,
        method: 'GET',
        data: {},
        params: {
          blNo: blNo
        }
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  searchInvoiceDetailInfo (blNo) {
    return new Promise((resolve, reject) => {
      Send({
        url: `${baseUrl}/invoice/detail`,
        method: 'GET',
        data: {},
        params: {
          blNo: blNo
        }
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  searchChargeList (blNo) {
    return new Promise((resolve, reject) => {
      Send({
        url: `${baseUrl}/charges`,
        method: 'GET',
        data: {},
        params: {
          blNo: blNo
        }
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  updateData (obj) {
    const jsonObj = JSON.stringify(obj)
    return new Promise((resolve, reject) => {
      Send({
        url: `${baseUrl}/charges`,
        method: 'PUT',
        data: obj,
        params: {}
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
