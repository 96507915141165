var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content_box beforetab" }, [
      _c("table", { staticClass: "tbl_search" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.009")))]),
            _c("td", [_vm._v(_vm._s(_vm.freightBlNo))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.010")))]),
            _c("td", [_vm._v(_vm._s(_vm.bkgNo))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.011")))]),
            _c("td", [_vm._v(_vm._s(_vm.blInfo.frtAppNo))]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.memberDetail.userCtrCd !== "IN",
            expression: "memberDetail.userCtrCd !== 'IN'",
          },
        ],
        staticClass: "content_box mt10",
      },
      [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(1),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.012")))]),
              _c(
                "td",
                _vm._l(_vm.invoiceList, function (item) {
                  return _c("div", { key: item, staticClass: "link-pdf" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openInvoice.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon pdf",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openInvoice.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("첨부")]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "content_box mt10" }, [
      _vm.isDisabled()
        ? _c("table", { staticClass: "tbl_search" }, [
            _vm._m(2),
            _c("tbody", [
              _c("tr", [
                _vm._m(3),
                _c("td", { staticClass: "text_right" }, [
                  _c("span", { staticClass: "box_info past" }, [
                    _vm.chargeInfo.blYn === "Y"
                      ? _c("span", { staticClass: "note" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.026"))),
                        ])
                      : _vm.chargeInfo.eobYn === "Y"
                      ? _c("span", { staticClass: "note" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.027"))),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      this.ofPCount > 0
        ? _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.013"))),
          ])
        : _vm._e(),
      this.ofPCount > 0
        ? _c("table", { staticClass: "tbl_col" }, [
            _vm._m(4),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.016")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.017")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.018")))]),
                _c("th", { attrs: { colspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.019"))),
                ]),
              ]),
            ]),
            _vm.chargeData.oceanFreight.length > 0
              ? _c(
                  "tbody",
                  [
                    _vm._l(_vm.chargeData.oceanFreight, function (item) {
                      return [
                        item.frtPncCd == "P"
                          ? _c("tr", { key: item.caSeq }, [
                              _c("td", { staticClass: "text-left" }, [
                                _vm._v(_vm._s(item.frtNm)),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.occrCurCd))]),
                              _c("td", { staticClass: "text_right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getCurrencyFormat(
                                      item.occrCurCd,
                                      item.occrAmt
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "td",
                                { attrs: { colspan: "2" } },
                                [
                                  item.frtPncCd == "P"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("msg.ONEX030T010.020")
                                            )
                                        ),
                                      ]
                                    : item.frtPncCd == "C"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("msg.ONEX030T010.021")
                                            )
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              : _c("tbody", [_vm._m(5)]),
          ])
        : _vm._e(),
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.014"))),
      ]),
      _c("table", { staticClass: "tbl_col" }, [
        _vm._m(6),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.016")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.017")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.018")))]),
            _c("th", { attrs: { colspan: "2" } }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.019"))),
            ]),
          ]),
        ]),
        _vm.chargeData.polCharge.length > 0
          ? _c(
              "tbody",
              _vm._l(_vm.chargeData.polCharge, function (item) {
                return _c("tr", { key: item.caSeq }, [
                  _c("td", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(item.frtNm)),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.occrCurCd))]),
                  _c("td", { staticClass: "text_right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.getCurrencyFormat(item.occrCurCd, item.occrAmt)
                      )
                    ),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.frtPncCd,
                          expression: "item.frtPncCd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: `pay-term-${item.caSeq}-1`,
                        name: `pay-term-${item.caSeq}`,
                        disabled: _vm.isDisabled(),
                      },
                      domProps: {
                        value: "P",
                        checked: _vm._q(item.frtPncCd, "P"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onPcClick($event)
                        },
                        change: function ($event) {
                          return _vm.$set(item, "frtPncCd", "P")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: `pay-term-${item.caSeq}-1` } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.020"))),
                      ]
                    ),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.frtPncCd,
                          expression: "item.frtPncCd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: `pay-term-${item.caSeq}-2`,
                        name: `pay-term-${item.caSeq}`,
                        disabled: _vm.isDisabled(),
                      },
                      domProps: {
                        value: "C",
                        checked: _vm._q(item.frtPncCd, "C"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onPcClick($event)
                        },
                        change: function ($event) {
                          return _vm.$set(item, "frtPncCd", "C")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: `pay-term-${item.caSeq}-2` } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.021"))),
                      ]
                    ),
                  ]),
                ])
              }),
              0
            )
          : _c("tbody", [_vm._m(7)]),
        _c(
          "tfoot",
          _vm._l(_vm.chargeTotal.pol, function (item, i) {
            return _c("tr", { key: item.occrCurCd }, [
              i == 0
                ? _c("th", { attrs: { rowspan: _vm.chargeTotal.pol.length } }, [
                    _vm._v("Total"),
                  ])
                : _vm._e(),
              _c(
                "th",
                { staticStyle: { "border-left": "1px solid #e1e3eb" } },
                [_vm._v(_vm._s(item.occrCurCd))]
              ),
              _c("th", { staticClass: "text_right" }, [
                _vm._v(
                  _vm._s(_vm.getCurrencyFormat(item.occrCurCd, item.total))
                ),
              ]),
              _c("th", { attrs: { colspan: "2" } }),
            ])
          }),
          0
        ),
      ]),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chargeData.polCharge.length > 0,
              expression: "chargeData.polCharge.length > 0",
            },
          ],
          staticClass: "text_right mt10",
        },
        [
          !_vm.isInvoicePub
            ? _c(
                "a",
                {
                  staticClass: "button sm blue",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.invoicePublish.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.007")))]
              )
            : _vm._e(),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.auth.userCtrCd === "KR",
                  expression: "auth.userCtrCd === 'KR'",
                },
              ],
              staticClass: "button sm blue ml5",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.requestFreightModify.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.008")))]
          ),
        ]
      ),
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.015"))),
      ]),
      _c("table", { staticClass: "tbl_col" }, [
        _vm._m(8),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.016")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.017")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.018")))]),
            _c("th", { attrs: { colspan: "2" } }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.019"))),
            ]),
          ]),
        ]),
        _vm.chargeData.podCharge.length > 0
          ? _c(
              "tbody",
              _vm._l(_vm.chargeData.podCharge, function (item) {
                return _c("tr", { key: item.caSeq }, [
                  _c("td", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(item.frtNm)),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.occrCurCd))]),
                  _c("td", { staticClass: "text_right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.getCurrencyFormat(item.occrCurCd, item.occrAmt)
                      )
                    ),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.frtPncCd,
                          expression: "item.frtPncCd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: `pay-term-${item.caSeq}-1`,
                        name: `pay-term-${item.caSeq}`,
                        disabled: _vm.isDisabled(),
                      },
                      domProps: {
                        value: "P",
                        checked: _vm._q(item.frtPncCd, "P"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onPcClick($event)
                        },
                        change: function ($event) {
                          return _vm.$set(item, "frtPncCd", "P")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: `pay-term-${item.caSeq}-1` } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.020"))),
                      ]
                    ),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.frtPncCd,
                          expression: "item.frtPncCd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: `pay-term-${item.caSeq}-2`,
                        name: `pay-term-${item.caSeq}`,
                        disabled: _vm.isDisabled(),
                      },
                      domProps: {
                        value: "C",
                        checked: _vm._q(item.frtPncCd, "C"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onPcClick($event)
                        },
                        change: function ($event) {
                          return _vm.$set(item, "frtPncCd", "C")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { attrs: { for: `pay-term-${item.caSeq}-2` } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.021"))),
                      ]
                    ),
                  ]),
                ])
              }),
              0
            )
          : _c("tbody", [_vm._m(9)]),
        _c(
          "tfoot",
          _vm._l(_vm.chargeTotal.pod, function (item, inx) {
            return _c("tr", { key: item.occrCurCd }, [
              inx === 0
                ? _c("th", { attrs: { rowspan: _vm.chargeTotal.pod.length } }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX030T010.022"))),
                  ])
                : _vm._e(),
              _c("th", [_vm._v(_vm._s(item.occrCurCd))]),
              _c("th", { staticClass: "text_right" }, [
                _vm._v(
                  _vm._s(_vm.getCurrencyFormat(item.occrCurCd, item.total))
                ),
              ]),
              _c("th", { attrs: { colspan: "2" } }),
            ])
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "flex_box mt10" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.auth.userCtrCd === "KR",
              expression: "auth.userCtrCd === 'KR'",
            },
          ],
          staticClass: "button lg",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.goWorkingList.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("msg.ONEX030T010.025")))]
      ),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.auth.userCtrCd === "KR",
              expression: "auth.userCtrCd === 'KR'",
            },
          ],
          staticClass: "button blue lg ml_auto",
          attrs: { type: "button" },
          on: { click: _vm.save },
        },
        [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
      ),
    ]),
    _c(
      "div",
      { attrs: { id: "layerInvoice" } },
      [
        _c(
          "win-layer-pop",
          [
            _vm.custCompInvoice
              ? _c(_vm.custCompInvoice, {
                  tag: "component",
                  attrs: {
                    "is-show-pop": _vm.isInvoiceShowPop,
                    "parent-info": _vm.invoiceParam,
                    detail: _vm.requestDetail,
                  },
                  on: { close: _vm.closeInvoce },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "70px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "70px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "70px" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200px" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("h2", { staticClass: "content_title" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("No data.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("No data.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("No data.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }